<template>
    <div v-if="!!statistics && !!statistics.datasets">
        <div class="columns">
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <graphed-statistic-card
                    id="active_listings"
                    :title="translate('Active Listings')"
                    :count="0"
                    :graph-data="graph_data('active_listings')"
                    :graph-only="true"
                    :zoomed="zoomed"
                    color="primary"
                    :height="57"
                    @toggle-zoom="toggle_zoom"
                />
            </div>
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <graphed-statistic-card
                    id="active_sales"
                    :title="translate('Sales')"
                    :count="0"
                    :graph-data="graph_data('active_sales')"
                    :graph-only="true"
                    :zoomed="zoomed"
                    color="primary"
                    :height="57"
                    @toggle-zoom="toggle_zoom"
                />
            </div>
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Average Days on Market"
                    :count="statistics.avg_days_on_market || 0"
                    glyph="calendar"
                    color="primary"
                />
            </div>
            <div class="column col-3 col-xl-6 col-sm-12 mb-4">
                <counted-statistic
                    title="Average Price/Sale"
                    :count="(statistics.avg_price_to_sale_ratio / 100) || 0"
                    glyph="chart-line"
                    color="primary"
                    :as-percentage="true"
                />
            </div>
        </div>
        <destination-modal
            v-if="!!zoomed"
            :show.sync="zoomed"
            :title="zoom_title"
        />
    </div>
</template>

<script>
import { uses_destination_modal } from "@/custom/mixins"

import { GraphedStatisticCard, CountedStatistic } from "@/custom/components"

export default {
    name: "ListingStatistics",
    mixins: [uses_destination_modal],
    components: {
        GraphedStatisticCard,
        CountedStatistic
    },
    methods: {
        graph_data (graph_id) {
            if(!this.statistics || !this.statistics.datasets) return {}
            const graph_data = {}
            const keys = Object.keys(this.statistics.datasets)
            keys.forEach(key => {
                graph_data[key] = this.statistics.datasets[key][graph_id]
            })
            return graph_data
        }
    },
    props: {
        statistics: {
            type: Object,
            required: true
        }
    }
}
</script>
