<template>
    <div class="listings-list">
        <listing-statistics
            :statistics="statistics"
            class="mb-8"
        />
        <div class="data-table-controls print-hide mb-4">
            <div class="columns">
                <div class="column">
                    <data-table-search-box
                        id="listings-list-searchbox"
                        :placeholder="translate('Search by MLS # or address')"
                        :value="state.search"
                        class="mb-4"
                        @input="search"
                    />
                </div>
                <div class="column no-wrap">
                    <list-mode-picker
                        :list-mode="list_mode"
                        @input="set_list_mode"
                        @refresh="refresh"
                    />
                </div>
            </div>
            <expandable>
                <template v-slot:title>
                    {{ translate("Advanced Search") }}
                </template>
                <listing-search-panel
                    :query="state.advanced_query"
                    @input="adjust_query"
                />
            </expandable>
        </div>
        <div v-if="!!data_version && !state.total" class="empty">
            <div class="empty-icon">
                <open-icon glyph="sign" size="5x" />
            </div>
            <p class="empty-title h5">
                <span v-if="realtorId">{{ translate("You do not have any listings... yet!") }}</span>
                <span v-else>{{ translate("No listings available... yet!") }}</span>
            </p>
            <div class="empty-action">
                <default-button
                    v-if="realtorId"
                    color="primary"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon
                        glyph="plus"
                        class="mr-2"
                    />
                    {{ translate("Add a Listing...") }}
                </default-button>
                <default-button
                    v-else-if="is_at_least_realtor"
                    color="primary"
                    :disabled="syncing"
                    @click.prevent="synchronize"
                >
                    <open-icon
                        glyph="sync"
                        :spin="syncing"
                        class="mr-2"
                    />
                    {{ translate("Launch initial import") }}
                </default-button>
            </div>
        </div>
        <listings-list
            v-else
            :list-mode="list_mode"
            :listings="filtered_rows"
            :state="state"
            :data-version="data_version"
            :loading="loading"
            :can-add="is_at_least_realtor"
            @sort="sort_by"
            @page="goto_page"
        />
    </div>
</template>

<script>
import { is_remote_data_table_source, string_utilities } from "@/nibnut/mixins"
import { lists_listings, addl_profile_utilities } from "@/custom/mixins"

import {
    DefaultButton,
    OpenIcon,
    DataTableSearchBox
} from "@/nibnut/components"
import {
    Expandable,
    ListingSearchPanel,
    ListingsList
} from "@/custom/components"
import ListingStatistics from "./ListingStatistics"

export default {
    name: "ListingsPageContent",
    mixins: [is_remote_data_table_source, string_utilities, lists_listings, addl_profile_utilities],
    components: {
        DefaultButton,
        OpenIcon,
        DataTableSearchBox,
        Expandable,
        ListingSearchPanel,
        ListingStatistics,
        ListingsList
    },
    watch: {
        loading: "signal_loading"
    },
    methods: {
        signal_loading () {
            this.$emit("loading", this.loading && !this.data_version)
        },
        reset () {
            if(!this.data_version && !!this.$route.query) {
                let state = {
                    filter_on: this.$route.query.filter_on || null,
                    filter: this.$route.query.filter || null
                }
                if(!this.stored_state) {
                    state = {
                        ...state,
                        ...this.constants("base_listing_query"),
                        ...(this.profile.search_context || {})
                    }
                }
                this.set_state_values(state)
            }
            this.refresh()
        },
        pre_load () {
            if(this.state.realtor_id !== this.realtorId) this.set_state_value("realtor_id", this.realtorId)
            this.is_infinite = (this.list_mode === this.DISPLAY_MODE_CARDS)
        },
        post_load (response) {
            this.statistics = response.meta.statistics || {}
            this.center = this.filtered_rows.length ? { lat: this.filtered_rows[0].latitude, lng: this.filtered_rows[0].longitude } : undefined
        },
        adjust_query (value, name) {
            if(this.state.advanced_query[name] !== value) {
                const advanced_query = { ...this.state.advanced_query }
                advanced_query[name] = value
                this.filtered_by_selection = false
                this.set_state_values({
                    page: 1,
                    advanced_query
                })
                this.refresh()
            }
        },
        sort_by (field, sort) {
            this.set_state_values({
                sort_by: field,
                sort_dir: sort
            })
            return this.refresh()
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            return rows
        },
        synchronize () {
            this.syncing = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: this.entity,
                    action: "sync",
                    method: "post",
                    passthru: true
                }
            ).then(message => {
                this.$success(message)
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.syncing = false
            })
        }
    },
    computed: {
        state_identifier () {
            return this.stateIdentifier
        },
        fields () {
            return ["fieldset::list"]
        }
    },
    props: {
        stateIdentifier: {
            type: String,
            required: true
        },
        realtorId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "listing",
            default_state: {
                per_page: 12,
                page: 1,
                sort_by: "days_on_market",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                advanced_query: {},
                realtor_id: 0,
                statistics: "list"
            },
            statistics: {},
            syncing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.listings-list {
    .card.card-expandable {
        overflow: visible;
        box-shadow: none;

        .card-header,
        .card-body,
        .card-footer {
            background-color: $body-bg;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
</style>
