<template>
    <page
        id="listings-list"
        :title="translate('Listing:::Listings', {}, 2)"
        :status="page_status"
        :waiting="loading"
        :is-root="true"
        @statused="statused"
    >
        <listings-page-content
            state-identifier="listings-list"
            @loading="loading = $event"
        />
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/nibnut/mixins"

import ListingsPageContent from "./ListingsPageContent"

export default {
    mixins: [is_page],
    components: {
        ListingsPageContent
    },
    data () {
        return {
            loading: true
        }
    }
}
</script>
